<script>
  import { fade } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  import { getInvitationCodeError } from "../../../invitation-code-errors";
  import { _ } from "svelte-i18n";

  const dispatch = createEventDispatcher();

  export let isOpen = false,
    message = "";
  let code = "";
  let errorMessage = message;

  async function checkCode() {
    try {
      let host = "";
      if (process.env.config.environment == "development") {
        host = process.env.config.localServerUrl;
      }
      const response = await fetch(
        host + `/api/v2/ovate/verify-code?code=${code.toUpperCase()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        dispatch("code_verified", { code_verified: true, data: data.data });
      } else {
        errorMessage = getInvitationCodeError(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  }
</script>

{#if isOpen}
  <div class="modal-consent" transition:fade>
    <div class="modal-content-consent">
      <p style="margin-bottom: 10px">
        {@html $_("products.please_type_invitation_code")}
      </p>
      <input type="text" bind:value={code} placeholder="Enter your code" />
      <p style="color: rgb(255, 0, 0); margin-bottom: 10px">
        {@html errorMessage}
      </p>
      <button
        class="agree-button-consent"
        on:click={checkCode}
        on:keydown={(event) => {
          if (event.key === "Enter" || event.key === "Space") {
            checkCode();
          }
        }}>Check Code</button
      >
    </div>
  </div>
{/if}

<style>
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  /* Button */
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }

  button:hover {
    background-color: #0056b3;
  }

  .modal-consent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .modal-content-consent {
    background-color: #fff;
    padding: 1.7rem;
    border-radius: 4px;
    text-align: center;
    color: #113433;
    animation: fadeIn 0.3s;
    width: 500px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    overflow-y: hidden;
  }

  @media screen and (max-width: 768px) {
    .modal-content-consent {
      overflow-y: scroll;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .agree-button-consent {
    background-color: #1d807c;
    color: #fff;
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  .agree-button-consent:hover {
    background-color: #146e6a;
  }
</style>
