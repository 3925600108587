<!-- routify:options bundle=true -->
<script>
  export let scoped;

  import {
    blankCustomerDetails,
    fetchJSON,
    loadOrInitialiseSession,
    getCountryCode,
    getPrice,
    getAccessibleStages,
    updateSession,
  } from "../../utils.js";
  import { loadingCount } from "../../stores.js";
  import Error from "../../components/Error.svelte";
  import Header from "../../components/Header.svelte";
  import Steps from "../../components/Steps.svelte";
  import { register, locale, init } from "svelte-i18n";
  import { onMount } from "svelte";
  import { goto, params } from "@roxi/routify";
  import { getInvitationCodeError } from "../../invitation-code-errors.js";
  import VerifyCode from "./[InviteCode]/VerifyCode.svelte";
  import Locale from "../../locale/Locale.svelte";
  import { getServerState } from "../../utils";

  let code_verified = false,
    data = null;
  let { InviteCode } = $params;

  register("en", () => import("../../../public/lang/en.json"));
  locale.set("en");
  localStorage.setItem("enrol-app-locale", "en");
  init({
    fallbackLocale: "en",
    initialLocale: "en",
  });

  const { loading: parentLoading } = scoped;

  const session = loadOrInitialiseSession("ovate", {
    selectedPriceID: null,
    customerDetails: blankCustomerDetails(),
    selectedCountryCode: null,
  });

  let prices, props;
  $: {
    const price = prices && getPrice(prices, $session.selectedPriceID);

    props = {
      ...scoped,
      price,
      prices,
      session,
      countryCode: getCountryCode({
        detectedCountryCode: scoped.detectedCountryCode,
        selectedCountryCode: $session.selectedCountryCode,
      }),
      accessibleStages: getAccessibleStages($session),
      countryCodeIsSelected: $session.selectedCountryCode !== null,
    };
  }

  let language;
  if (localStorage.getItem("enrol-app-locale")) {
    language = localStorage.getItem("enrol-app-locale");
  } else {
    language = "en";
  }

  let maintenance_message;
  let enabled;
  getServerState(language).then((res) => {
    if (res.enabled_maintenance_page) {
      maintenance_message = res.maintenance_page;
      enabled = res.enabled_maintenance_page;
    }
  });
  // The codes are filtered to exclude non-text offerings, as these have run out
  const loading = Promise.all([
    parentLoading,
    fetchJSON("/api/v1/shops/ovate/prices").then(({ data }) => {
      prices = data.filter((price) =>
        ["ovate_text", "text", "online", "text_online"].includes(
          price.variant.product.code
        )
      );
    }),
  ]);

  loadingCount.update((n) => n + 1);
  loading.finally(() => loadingCount.update((n) => n - 1));

  async function checkCode(InviteCode) {
    try {
      let host = "";
      if (process.env.config.environment == "development") {
        host = process.env.config.localServerUrl;
      }
      const response = await fetch(
        host + `/api/v2/ovate/verify-code?code=${InviteCode.toUpperCase()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        return { code_verified: true, data: data.data };
      }
      return {
        code_verified: false,
        message: getInvitationCodeError(data.error),
      };
    } catch (error) {
      console.error(error);
    }
  }

  function openModalToVerifyCode(message = "") {
    const verifyCodeModal = new VerifyCode({
      target: document.body,
      props: {
        isOpen: true,
        message,
      },
    });
    verifyCodeModal.$on("code_verified", (event) => {
      code_verified = event.detail.code_verified;
      if (code_verified) {
        data = event.detail.data;
        updateSession(session, {
          code_verified: code_verified,
          invitationData: data,
        });
        $goto("./package");
        verifyCodeModal.$destroy();
      }
    });
  }
  onMount(
    async () => {
      if (InviteCode) {
        await checkCode(InviteCode).then((result) => {
          code_verified = result.code_verified;
          if (!code_verified) {
            openModalToVerifyCode(result.message);
          } else {
            data = result.data;
          }
        }, code_verified);
      } else {
        openModalToVerifyCode();
      }
      if (code_verified) {
        updateSession(session, {
          code_verified: code_verified,
          invitationData: data,
        });
        $goto("./package");
      }
    },
    code_verified,
    session,
    data
  );
</script>

<Locale />
{#if !enabled}
  <Header title="Ovate Grade Enrolment">
    <Steps />
  </Header>
{/if}

{#await loading}
  <!-- Loading -->
{:then}
  {#if code_verified}
    <main>
      {#if enabled}
        {@html maintenance_message}
      {:else}
        <slot scoped={props} />
      {/if}
    </main>
  {/if}
{:catch error}
  <Error {error} />
{/await}
