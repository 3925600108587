<script>
  import { updateSession, getPrice, getRegion } from "../../utils.js";
  import { goto, url } from "@roxi/routify";

  import CountryName from "../../components/CountryName.svelte";
  import CurrencyConverter from "../../components/CurrencyConverter.svelte";
  import PriceDisplay from "../../components/PriceDisplay.svelte";
  import ProductSelect from "../../components/ProductSelect.svelte";
  import VariantSelect from "../../components/VariantSelect.svelte";
  import ConsentModal from "../../components/ConsentModal.svelte";
  import { _ } from "svelte-i18n";
  import LocaleSetting from "../../locale/LocaleSetting.svelte";
  import ContactUsForm from "../../components/ContactUsForm.svelte";

  export let scoped;
  const {
    countries,
    symbols,
    rates,
    prices,
    session,
    countryCode,
    countryCodeIsSelected,
  } = scoped;
  let isOpen = false;

  const invitationData = $session.invitationData;
  let language;
  if (invitationData.Language) {
    language = invitationData.Language.toLowerCase();
  } else if (localStorage.getItem("enrol-app-locale")) {
    language = localStorage.getItem("enrol-app-locale");
  } else {
    language = "en";
  }

  const region = getRegion(countries, countryCode);
  function filterPrices(language) {
    if (language == "en") {
      let location = "GB";
      if (region == "EU" || region == "ROW") {
        location = "ROW";
      }
      return prices.filter(
        (price) => price.language === language && price.region.code === location
      );
    }
    return prices.filter((price) => price.language === language);
  }

  let regionalPrices = filterPrices(language);
  $: regionalPrices = filterPrices(language);

  let initialPrice = getPrice(regionalPrices, $session.selectedPriceID);
  $: initialPrice = getPrice(regionalPrices, $session.selectedPriceID);

  let product;
  product = initialPrice ? initialPrice.variant.product : null;

  let pricesForProduct;
  $: pricesForProduct = product
    ? regionalPrices.filter((price) => price.variant.product.id === product.id)
    : [];

  let variant;
  variant = initialPrice ? initialPrice.variant : null;

  let price;
  $: {
    price =
      pricesForProduct && variant
        ? pricesForProduct.find((price) => price.variant.id === variant.id)
        : null;
    updateSession(session, {
      selectedPriceID: price?.id,
      selectedPriceRegionCode: price?.region.code,
    });
  }

  let toggleCurrencyConverter;

  const productDetails = {
    ovate_text: {
      description: "products.printed",
      blurb: `products.ovate_description_printed`,
    },
    text: {
      description: "products.printed",
      blurb: `products.ovate_description_printed`,
      type: `ovate_`,
    },
    online: {
      description: "products.online",
      blurb: "products.ovate_description_online",
      type: `ovate_`,
    },
    online: {
      description: "products.online",
      blurb: "products.ovate_description_online",
    },
    text_online: {
      description: "products.Both Print & Online",
      blurb: "products.Text for Ovate Print & Online Product",
      type: `ovate_`,
    },
    ovate_audio: {
      description: "Audio CD",
      blurb: `
        Receive the full Ovate Course in Audio CD format, including
        supplementary materials posted monthly over 9 months.  Also you’ll
        receive by email the monthly Touchstone magazine, have access to
        additional private web forums for this grade, and a personal mentor.
      `,
    },
    ovate_text_audio: {
      description: "Both Print & Audio CD",
      blurb: `
        Receive the full Ovate Course in Printed booklet with accompanying
        Audio CDs, including supplementary materials posted monthly over 9
        months.  Also you’ll receive by email the monthly Touchstone magazine,
        have access to additional private web forums for this grade, and a
        personal mentor.
      `,
      image: false,
    },
  };

  let baseCurrency;
  $: baseCurrency = changeBaseCurrency(language);

  let isConsentGiven = false;

  function formSubmit() {
    if (invitationData.GetConsent) {
      const consentModal = new ConsentModal({
        target: document.body,
        props: {
          isOpen: true,
        },
      });
      consentModal.$on("consent", (event) => {
        isConsentGiven = event.detail.agreed;
        consentModal.$destroy();
        if (isConsentGiven) {
          updateSession(session, {
            selectedPriceID: price.id,
            selectedPriceRegionCode: price.region.code,
            isConsentGiven: isConsentGiven,
          });
          $goto("./details");
        }
      });
    } else {
      updateSession(session, {
        selectedPriceID: price.id,
        selectedPriceRegionCode: price.region.code,
        isConsentGiven: isConsentGiven,
      });
      $goto("./details");
    }
  }

  function changeBaseCurrency() {
    if (language === "en") {
      return "gbp_rates";
    }
    return "eur_rates";
  }

  function handleLanguageChange(event) {
    language = event.detail;
    regionalPrices = prices.filter((price) => price.language === event.detail);
    baseCurrency = changeBaseCurrency(event.detail);
    regionalPrices = filterPrices(event.detail);
    initialPrice = getPrice(regionalPrices, $session.selectedPriceID);
  }
</script>

<LocaleSetting {language} on:languageChange={handleLanguageChange} />
<form on:submit|preventDefault={formSubmit}>
  <section>
    <h2>
      {$_("choice.greetings", {
        values: { firstName: invitationData.FirstName },
      })},
    </h2>
    <p class="space-under">
      {$_("choice.greeting_text", {
        values: { grade: invitationData.Grade },
      })}:
    </p>
    <ProductSelect
      prices={regionalPrices}
      details={productDetails}
      order={[
        "ovate_text",
        "ovate_audio",
        "ovate_text_audio",
        "online",
        "text_online",
      ]}
      bind:product
    />
  </section>

  <VariantSelect prices={pricesForProduct} bind:variant />

  <section class="links">
    <div class="change-location">
      <p>
        {#if countryCodeIsSelected}
          {$_("choice.selected_location_and_currency")}: <CountryName
            {countries}
            {countryCode}
          />
        {:else}
          {$_("choice.detected_location_and_currency")}: <CountryName
            {countries}
            {countryCode}
          />
        {/if}
      </p>
      <a href={$url("./country")}>&gt; {$_("choice.change_my_location")}</a>
    </div>

    <div class="course-companion-link">
      <p>
        Registering as a Course Companion? - Please do not enrol here, but
        follow this link instead to register yourself:
      </p>
      <a
        target="_blank"
        href="https://store.druidry.org/collections/obod-course-extras/products/ovate-course-additional-member-reg-fee"
        >&gt; Course Companion Registration</a
      >
    </div>

    {#if price}
      <div class="currency-converter">
        <p>{$_("choice.click_to_change_currency")}</p>
        <button on:click|preventDefault={toggleCurrencyConverter}
          >{$_("choice.currency_converter")}</button
        >
      </div>
    {/if}

    <button
      class="open"
      on:click|preventDefault={() => {
        isOpen = true;
      }}>> Feedback / Problems? Contact us</button
    >
  </section>

  <CurrencyConverter
    bind:toggle={toggleCurrencyConverter}
    {countries}
    {baseCurrency}
    {symbols}
    {rates}
    {price}
    {countryCode}
  />

  <ContactUsForm bind:isOpen />

  <section class="btn-container">
    <div class="btn-container__inside">
      <PriceDisplay {price} />
      <button disabled={!price} class="btn btn--primary"
        >{$_("choice.next")}</button
      >
    </div>
  </section>
</form>

<style>
  @import "../../styles/variables.css";

  .space-under {
    margin-bottom: 1rem;
  }

  .links {
    margin-top: 2em;
  }

  .change-location,
  .currency-converter,
  .course-companion-link {
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
  }

  .currency-converter button {
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
  }

  .currency-converter button::before {
    content: "> ";
  }

  .currency-converter button:hover {
    cursor: pointer;
  }

  .open {
    margin-bottom: var(--baseline);
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
    cursor: pointer;
  }
</style>
